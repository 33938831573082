import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundEdgeTower2 from "../components/background/BackgroundEdgeTower2"
import ContactUs from "../components/ContactUs"

const Page = () => (
  <Layout>
    <PageHeader
      title="Edge Cloud Benchmarking"
      subtitle="Benchmark various Edge sites using Voereir’s benchmarking tool"
    />
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-right height-400 width-100">
              <BackgroundEdgeTower2 />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Edge infrastructures</h2>
            </div>
            <p className="paragraph-3">
              VoerEir offers to Edge infrastructure providers the capability to benchmark and evaluate the performance of edge sites.
            </p>
            <p className="paragraph-3">
              Several KPIs can be tested using Touchstone to give the performance snapshot of your edge site. Test your edge site for infrastructure performance, API compliance, security score, robustness and many more areas.
            </p>
            <p className="paragraph-3">
              Touchstone also support ETSI MEC and Intel's OpenNESS framework.
            </p>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default Page
